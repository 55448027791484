<template>
  <div>
    <el-table
        @row-click="rowClick"
        :data="tableData"
        :max-height="900"
        style="width: 100%">
      <el-table-column
          align="center"
          prop="radio"
          label="单选"
          fixed="left"
         >
        <template slot-scope="scope">
          <svg v-if="indexList === scope.$index" t="1718613548086" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2728" width="20" height="20"><path d="M512 985C250.77 985 39 773.23 39 512S250.77 39 512 39s473 211.77 473 473-211.77 473-473 473z" fill="#2F54EB" p-id="2729"></path><path d="M391.84 648.34l321.887-321.635c15.627-15.615 40.953-15.605 56.568 0.022 15.615 15.627 15.605 40.953-0.022 56.568L419.957 733.338c-15.679 15.666-41.108 15.597-56.7-0.156L201.571 569.845c-15.541-15.7-15.412-41.027 0.288-56.568 15.7-15.541 41.026-15.413 56.568 0.288L391.84 648.34z" fill="#FFFFFF" p-id="2730"></path></svg>
          <svg v-else t="1718613083329" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1582" width="20" height="20"><path d="M512 1024C229.2224 1024 0 794.7776 0 512 0 229.2224 229.2224 0 512 0c282.7776 0 512 229.2224 512 512 0 282.7776-229.2224 512-512 512z m0-34.133333c263.918933 0 477.866667-213.947733 477.866667-477.866667S775.918933 34.133333 512 34.133333 34.133333 248.081067 34.133333 512s213.947733 477.866667 477.866667 477.866667z" fill="#999999" p-id="1583"></path></svg>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          :prop="item.prop"
          :label="item.label"
          :fixed="item.fixed"
          :width="100" v-for="(item, index) in columns" :key="index">
        <template slot-scope="scope">
          <div v-html="scope.row[item.prop]">
          
          </div>
        </template>
          <el-table-column
              :prop="child.prop"
              :label="child.label"
              :width="60" v-for="(child, index) in item.child" :key="index + '&'">
          </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "formulaSeven",
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    selectIndex: {
      type: [Number, null],
      required: false,
    }
  },
  
  watch: {
    data: {
      immediate: true, // 立即执行一次
      handler(newValue) {
        this.tableData = [...newValue];
      },
    },
    selectIndex: {
      immediate: true, // 立即执行一次
      handler(newValue) {
        this.indexList = newValue;
      },
    }
  },
  data () {
    return {
      indexList: null,
      tableData: []
    }
  },
  methods: {
    rowClick (row) {
      const index = this.tableData.findIndex((item) => JSON.stringify(item) === JSON.stringify(row))
      if (index !== -1) {
        this.indexList = index
      }
      this.$emit('change', row, this.indexList)
    }
  }
}
</script>

<style scoped>
::v-deep .el-table__body-wrapper{
            z-index: 2
        }

 /* ::v-deep .el-table__fixed{
   height:auto !important;
   bottom:10px !important;
} */

</style>